.cc_image {
  width: 256px;
  height: 256px;
}

.cc_image.fallback {
  /* this URL here is theoretically a local one (always reachable) */
  background-image: url('https://cdn2.iconfinder.com/data/icons/picons-basic-3/57/basic3-010_creative_commons-256.png');
}

.topMargin {
  margin-top: 60px;
}

.sideMargin {
  margin-left: 50px;
}

.marginDisplace {
  margin-left: auto;
  margin-right: auto;
}

.bottomMargin {
  margin-top: 300px;
  display: block;
}

.titleMargin {
  margin-bottom: 350px;
  display: block;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

.text {
margin-bottom: -60px;
}

.line1 {
  height: 1px;
  background: white;
  max-width: 600px;
  margin: 2rem auto;
  margin-top: 75px;
  margin-bottom: 75px;
  display: block;
}

.margin-bottem {
margin-bottom: 200px;

}

img {
  display: block;
  max-width: 100%;
}

:root {
  --clr-primary-200: #f3eed9;
  --clr-primary-400: #824936;

  --clr-netral-100: #FFF;
  --clr-netral-100: #222C2A;

  --ff-primary: 'Roboto', sans-serif;
  --f-accent: 'Playfair Display', serif;
}

h1 {
  font-weight: 600;
  font-size: 6rem;
}

image {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
}

.container {
  max-width: 50rem;
  margin: 0 auto;
  width: min(90%, 70.5rem)
}

.viewportHeader
 {
  background: transparent;
  position: relative;
  height: 100%;
  width: 300px;
  
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.roundedBox {
  
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (min-width: 800px) {
    width: 400px;
  }

  width: 400px;
  display: block;
  border-radius: 25px;
  border: 1px solid white;
  padding: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: linear-gradient(to right bottom, rgb(61, 61, 92, 0.5), rgb(163, 163, 194, 0.5));
}

.centerTitle {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 500  px;
}

.parent {
  z-index: 10;
  height: 250px;
  width: 50%;
  display:block
}

.child {
  z-index: 10;
  height: 100%;
  width: 100%;
  transition: transform 1s ease-in;
}

.parent:hover .child {
  animation: left-to-right 1s ease-in forwards alternate infinite;

}
.parent:hover .child {
  animation: left-to-right 1s ease-in forwards alternate infinite;

}

@keyframes left-to-right {
  0% {
    transform: translateX(0);
  }
  33% {
    transform: translateY(5%);
  }
  66% {
    transform: translateX(1%) translateY(5%)
  }
  100% {
    transform: translateX(1%);
  }
}

h1 {
  color: rgba(0, 0, 0, .1);
  color: #000000FF;
  color: rgb(255, 255, 255);
  font-family: var(--FF-accent);
  line-height: 1;
}
.flexbox-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  width: 100%;
  margin: 10px;

}

.flexbox-container2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: space-around;
}

.flexbox-item {
  width: 200px;
  margin: 10px;
}

.flexbox-item-1 {

  min-height: 50px;
}

.flexbox-item-2 {
  min-height: 50px;
}

.flexbox-item-3 {
  min-height: 50px;
  margin-bottom: 15px;
}

.flexbox-item-4 {
  min-height: 100px;
}

.flexbox-item-5 {
  min-height: 100px;
}

.flexbox-item-6 {
  min-height: 100px;
}

body {
  background-color: #5e5e5e;
  color: white;
  margin: 0;
  padding: 0;
  font-family: var(--ff-primary);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  background-color: #282c34;
  color: white;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-light { 
  background-color: var(--clr-primary-200)
}

.bg-primary { 
  background-color: var(--clr-primary-400)
}

.bg- { 
  background-color: var(--clr-netral-100)
}
